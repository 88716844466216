import * as Ariakit from "@ariakit/react";
import type { CSSProperties, ReactNode } from "react";
import { css, cx } from "~/design/generated/css";
import { flex } from "~/design/generated/patterns";
import { button, dialog } from "~/design/generated/recipes";
import type { DialogVariantProps } from "~/design/generated/recipes";
import { Button } from "./Button";
import { Text } from "./Text";

export type DialogProps = DialogVariantProps &
  Ariakit.DialogProps & {
    dismissable?: boolean;
    size?: string;
    title?: string;
  };

const DEFAULT_ANIMATION_FOR_PLACEMENT = {
  center: "zoomIn",
  left: "slideInFromLeft",
  right: "slideInFromRight",
  top: "slideInFromTop",
  bottom: "slideInFromBottom",
};

export function Dialog({
  children,
  store,
  dismissable = true,
  placement,
  animation,
  size,
  className,
  title,
  ...props
}: DialogProps) {
  return (
    <Ariakit.Dialog
      {...props}
      store={store}
      className={cx(
        dialog({
          placement,
          animation: animation ? animation : placement ? DEFAULT_ANIMATION_FOR_PLACEMENT[placement] : "zoomIn",
        }),
        className,
      )}
      style={{ "--dialog-size": size } as CSSProperties}
      backdrop={
        <div
          className={css({
            position: "fixed",
            inset: 0,
            zIndex: 999,
            bg: "rgba(0, 0, 0, 0.2)",
            transition: "opacity 120ms ease-out",
            opacity: 0,
            "&[data-enter]": {
              opacity: 1,
            },
          })}
        />
      }
    >
      <>
        {title || dismissable ? (
          <div
            className={flex({
              h: 14,
              minH: 14,
              top: 0,
              px: 2,
              position: "sticky",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Text variant="heading-4">{title}</Text>
            <Ariakit.DialogDismiss
              render={({ children, ...props }) => (
                <Button startSlot="close" variant="ghost" iconOnly roundness={"full"} {...props} />
              )}
            />
          </div>
        ) : null}

        {children}
      </>
    </Ariakit.Dialog>
  );
}

import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const dialogFn = /* @__PURE__ */ createRecipe('dialog', {
  "placement": "center"
}, [])

const dialogVariantMap = {
  "placement": [
    "center",
    "top",
    "bottom",
    "left",
    "right"
  ],
  "animation": [
    "none",
    "zoomInTop",
    "zoomIn",
    "slideInFromTop",
    "slideInFromBottom",
    "slideInFromLeft",
    "slideInFromRight"
  ]
}

const dialogVariantKeys = Object.keys(dialogVariantMap)

export const dialog = /* @__PURE__ */ Object.assign(memo(dialogFn.recipeFn), {
  __recipe__: true,
  __name__: 'dialog',
  __getCompoundVariantCss__: dialogFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: dialogVariantKeys,
  variantMap: dialogVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, dialogVariantKeys)
  },
  getVariantProps: dialogFn.getVariantProps,
})
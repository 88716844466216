import { type ReactNode, useEffect, useState } from "react";
import { tinykeys } from "tinykeys";
import { cx } from "~/design/generated/css";
import { badge } from "~/design/generated/recipes";
import type { BadgeProps } from "./Badge";

export type KbdProps = {
  children: ReactNode;
  className?: string;
  size: BadgeProps["size"];
};

export function Kbd({ children, className, ...props }: KbdProps) {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  useEffect(() => {
    function onKeyDown() {
      setIsKeyPressed(true);
    }

    function onKeyUp() {
      setIsKeyPressed(false);
    }

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);
  return <kbd className={cx(badge({ variant: "outline", size: "sm" }), className)}>{children}</kbd>;
}

import { MeiliSearch } from "meilisearch";
import { SearchBar } from "~/components/SearchBar";
import { atom } from "jotai";
import { css } from "~/design/generated/css";
import { Text } from "~/components/Text";
import { flex } from "~/design/generated/patterns";
import { getEnv } from "./PublicEnv";
import { useNavigate } from "@remix-run/react";
import { Image } from "@unpic/react";
import type { MeiliesearchModel } from "~/models/model.server";

export const searchAtom = atom<boolean>(false);

async function getSearchResults(q: string) {
  const client = new MeiliSearch({
    host: getEnv("MEILIESEARCH_HOST")!,
    apiKey: getEnv("MEILIESEARCH_API_KEY"),
  });

  const index = await client.getIndex("models");
  const results = await index.search(q, { hitsPerPage: 10 });
  return results.hits;
}

function ModelResult({ model }: { model: MeiliesearchModel }) {
  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
        gap: 2,
        py: {
          base: 2,
          sm: 3,
        },
      })}
    >
      <div className={css({ w: 24, aspectRatio: 1, p: 1 })}>
        <Image src={model.pictures?.[0]?.url} height={90} width={90} layout="constrained" />
      </div>

      <div
        className={flex({
          direction: "column",
          flex: 1,
          ml: 2,
          gap: 3,
        })}
      >
        <Text variant="label-1" color="neutral.soft">
          {model.brand.name}
        </Text>
        <Text variant="heading-4" maxLines={1}>
          {model.name}
        </Text>
        <Text variant="caption-1" color="neutral.soft">
          {model.referenceNumber}
        </Text>
      </div>
    </div>
  );
}

export function MeiliSearchSearchbar() {
  const navigate = useNavigate();

  return (
    <SearchBar
      atom={searchAtom}
      fetch={getSearchResults}
      placeholder="Search something"
      getKey={(item: any) => item.id}
      renderItem={(item: any) => <ModelResult model={item} />}
      onItemClick={({ item, dialogStore, comboboxStore }) => {
        dialogStore.hide();
        comboboxStore.setValue("");
        navigate(`/watches/${item.brand.slug}/${item.series.slug}/${item.slug}/listings`);
      }}
    />
  );
}

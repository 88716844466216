import * as Ariakit from "@ariakit/react";
import { type PrimitiveAtom, useAtomValue, useSetAtom } from "jotai";
import { debounce } from "lodash-es";
import { Fragment, type ReactNode, useCallback, useEffect, useState } from "react";
import { tinykeys } from "tinykeys";
import { css, cx } from "~/design/generated/css";
import { menuItem } from "~/design/generated/recipes";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { Icon } from "./Icon";
import { Kbd } from "./Kbd";
import { SegmentedControl, SegmentedControlItem } from "./SegmentedControl";
import { Text } from "./Text";
import { TextField } from "./TextField";

type SearchBarProps = {
  placeholder?: string;
  getKey: (item: any) => string;
  renderItem: (item: any) => ReactNode;
  onItemClick: (item: any) => void;
  fetch: (q: string) => any;
  atom: PrimitiveAtom<boolean>;
};

function SearchDialogFooter() {
  return (
    <footer
      className={css({
        borderTop: "1px solid {colors.neutral.border.faded}",
        py: 2,
        px: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "full",
      })}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 1,
          px: 2,
        })}
      >
        <Kbd size="md">↓</Kbd>
        <Kbd size="md">↑</Kbd>
        <Text variant="body-2" color="neutral.softest">
          to navigate
        </Text>
      </div>
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          gap: 1,
          px: 2,
          borderLeft: "1px solid {colors.neutral.border.faded}",
        })}
      >
        <Kbd size="md">Enter</Kbd>
        <Text variant="body-2" color="neutral.softest">
          to select
        </Text>
      </div>
    </footer>
  );
}

export function SearchBar({ placeholder = "Search...", getKey, renderItem, onItemClick, fetch, atom }: SearchBarProps) {
  const isSearchOpen = useAtomValue(atom);
  const setSearchOpen = useSetAtom(atom);

  const [items, setItems] = useState([]);

  const dialogStore = Ariakit.useDialogStore({
    animated: true,
    open: isSearchOpen,
    setOpen: setSearchOpen,
  });

  const debouncedFetch = useCallback(
    debounce(async (search: string) => {
      if (search.length < 2) return setItems([]);
      const result = await fetch(search);
      return setItems(result);
    }, 150),
    [],
  );

  const comboboxStore = Ariakit.useComboboxStore({
    resetValueOnHide: true,
    defaultOpen: true,
    focusLoop: true,
    includesBaseElement: false,
    setValue: debouncedFetch,
  });

  useEffect(() => {
    const unsubscribe = tinykeys(window, {
      "Meta+K": () => setSearchOpen(true),
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Fragment>
      <Button
        className={cx("group", css({ width: "100%" }))}
        onClick={() => setSearchOpen(true)}
        variant="soft"
        align="start"
        full
        startSlotAlign="side"
        size="md"
        startSlot={<Icon name="search-line" color="neutral.softest" size="md" />}
        endSlot={
          <Kbd
            size="sm"
            className={css({
              visibility: "hidden",
              _groupHover: { visibility: "visible" },
              _groupFocus: { visibility: "visible" },
            })}
          >
            ⌘ K
          </Kbd>
        }
        endSlotAlign="side"
      >
        <div
          className={css({
            color: "neutral.foreground.softest",
            fontWeight: "normal",
          })}
        >
          {placeholder}
        </div>
      </Button>
      <Dialog
        store={dialogStore}
        placement="top"
        animation="zoomInTop"
        dismissable={false}
        className={css({ width: { sm: "full", md: "700px" } })}
      >
        <div
          className={css({
            borderBottom: "solid 1px {colors.neutral.border.faded}",
            flexShrink: 0,
            py: 1,
            mx: -1,
          })}
        >
          <Ariakit.Combobox
            store={comboboxStore}
            autoSelect={true}
            placeholder={placeholder}
            autoCorrect="off"
            render={
              <TextField
                variant="headless"
                startSlot="search-line"
                autoComplete="off"
                autoCorrect="off"
                spellCheck={false}
              />
            }
          />
        </div>
        {/* <div
          className={css({
            borderBottom: "solid 1px {colors.neutral.border.faded}",
            flexShrink: 0,
            py: 3,
            px: 4,
          })}
        >
          <SegmentedControl defaultValue="ios" variant="soft" background="outline">
            <SegmentedControlItem value="ios">Models</SegmentedControlItem>
            <SegmentedControlItem value="android">Collections</SegmentedControlItem>
            <SegmentedControlItem value="web">Brands</SegmentedControlItem>
          </SegmentedControl>
        </div> */}
        <Ariakit.ComboboxList
          store={comboboxStore}
          className={css({
            maxH: 96,
            minH: 60,
            p: 2,
            overflowY: "scroll",
          })}
        >
          {items.map((item: any) => {
            return (
              <Ariakit.ComboboxItem
                key={getKey(item)}
                focusOnHover
                className={cx(menuItem({ autoHeight: true }), css({ scrollMargin: 2 }))}
                onClick={() => onItemClick({ item, comboboxStore, dialogStore })}
              >
                {renderItem(item)}
              </Ariakit.ComboboxItem>
            );
          })}
        </Ariakit.ComboboxList>
        <SearchDialogFooter />
      </Dialog>
    </Fragment>
  );
}

import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const badgeFn = /* @__PURE__ */ createRecipe('badge', {
  "variant": "solid",
  "color": "neutral",
  "size": "md",
  "roundness": "medium"
}, [
  {
    "size": "xs",
    "roundness": "none",
    "css": {
      "px": "calc(token(sizes.control.xs) * 0.15)",
      "height": "calc(token(sizes.control.xs) * 0.55)",
      "minWidth": "calc(token(sizes.control.xs) * 0.55)",
      "borderRadius": "0"
    }
  },
  {
    "size": "xs",
    "roundness": "medium",
    "css": {
      "px": "calc(token(sizes.control.xs) * 0.15)",
      "height": "calc(token(sizes.control.xs) * 0.55)",
      "minWidth": "calc(token(sizes.control.xs) * 0.55)",
      "borderRadius": " calc(token(radii.control.xs) * 0.6)"
    }
  },
  {
    "size": "xs",
    "roundness": "full",
    "css": {
      "px": "calc(token(sizes.control.xs) * 0.15)",
      "height": "calc(token(sizes.control.xs) * 0.55)",
      "minWidth": "calc(token(sizes.control.xs) * 0.55)",
      "borderRadius": "full"
    }
  },
  {
    "size": "sm",
    "roundness": "none",
    "css": {
      "px": "calc(token(sizes.control.sm) * 0.15)",
      "height": "calc(token(sizes.control.sm) * 0.55)",
      "minWidth": "calc(token(sizes.control.sm) * 0.55)",
      "borderRadius": "0"
    }
  },
  {
    "size": "sm",
    "roundness": "medium",
    "css": {
      "px": "calc(token(sizes.control.sm) * 0.15)",
      "height": "calc(token(sizes.control.sm) * 0.55)",
      "minWidth": "calc(token(sizes.control.sm) * 0.55)",
      "borderRadius": " calc(token(radii.control.sm) * 0.6)"
    }
  },
  {
    "size": "sm",
    "roundness": "full",
    "css": {
      "px": "calc(token(sizes.control.sm) * 0.15)",
      "height": "calc(token(sizes.control.sm) * 0.55)",
      "minWidth": "calc(token(sizes.control.sm) * 0.55)",
      "borderRadius": "full"
    }
  },
  {
    "size": "md",
    "roundness": "none",
    "css": {
      "px": "calc(token(sizes.control.md) * 0.15)",
      "height": "calc(token(sizes.control.md) * 0.55)",
      "minWidth": "calc(token(sizes.control.md) * 0.55)",
      "borderRadius": "0"
    }
  },
  {
    "size": "md",
    "roundness": "medium",
    "css": {
      "px": "calc(token(sizes.control.md) * 0.15)",
      "height": "calc(token(sizes.control.md) * 0.55)",
      "minWidth": "calc(token(sizes.control.md) * 0.55)",
      "borderRadius": " calc(token(radii.control.md) * 0.6)"
    }
  },
  {
    "size": "md",
    "roundness": "full",
    "css": {
      "px": "calc(token(sizes.control.md) * 0.15)",
      "height": "calc(token(sizes.control.md) * 0.55)",
      "minWidth": "calc(token(sizes.control.md) * 0.55)",
      "borderRadius": "full"
    }
  }
])

const badgeVariantMap = {
  "size": [
    "xs",
    "sm",
    "md"
  ],
  "color": [
    "neutral",
    "positive",
    "warning",
    "critical",
    "accent"
  ],
  "variant": [
    "solid",
    "soft",
    "outline"
  ],
  "roundness": [
    "none",
    "medium",
    "full"
  ]
}

const badgeVariantKeys = Object.keys(badgeVariantMap)

export const badge = /* @__PURE__ */ Object.assign(memo(badgeFn.recipeFn), {
  __recipe__: true,
  __name__: 'badge',
  __getCompoundVariantCss__: badgeFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: badgeVariantKeys,
  variantMap: badgeVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, badgeVariantKeys)
  },
  getVariantProps: badgeFn.getVariantProps,
})